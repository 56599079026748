import React from 'react';
import ModalVideo from 'react-modal-video';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import Link from '../../../core/Components/Link';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';
import ScrollAnimation from 'react-animate-on-scroll';

class LeftRightAlignVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    const {
      Id,
      title,
      description,
      image,
      videoId,
      hasFade,
      useIconLinkStyle,
      secondaryLink,
      secondaryLinkIcon,
      link,
      linkIcon,
      ariaLabel,
      backgroundColorOption,
      isSecondary,
    } = this.props.fields;
    const bgc = backgroundColorOption[0].fields.color.value;
    const content = (
      <div className="video-banner">
        <Image field={image} />
      </div>
    );
    const contentBlock = (
      <div
        className="LeftRightAlignVideo"
        style={{
          backgroundColor: bgc,
        }}
        id={Id ? Id.value : ''}
      >
        <div className="l-padding">
          <div className="product-media-title-mb">
            <Text field={title} tag="h2" />
          </div>
          <div
            className={`product-media-description lr-align-left ${
              !isSecondary.value ? 'is-active' : ''
            }`}
          >
            <div className="content-container__grouper">
              <div className="product-media-title-pc">
                <Text field={title} tag="h2" />
              </div>
              <RichText field={description} className="content" />
              {link.value.href ? (
                <div className="cta-grouping">
                  <React.Fragment>
                    {!linkIcon.value.src || !useIconLinkStyle.value ? (
                      <React.Fragment>
                        <Link field={link} className="cta-button-ni-link" />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Link field={link} className="cta-button-link">
                          <Image
                            field={linkIcon}
                            className="cta-button-image"
                          />
                        </Link>
                      </React.Fragment>
                    )}
                    <React.Fragment>
                      {secondaryLink.value.href ? (
                        <React.Fragment>
                          {!secondaryLinkIcon.value.src ||
                          !useIconLinkStyle.value ? (
                            <React.Fragment>
                              <Link
                                field={secondaryLink}
                                className="cta-button-ni-link secondary"
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Link
                                field={secondaryLink}
                                className="cta-button-link secondary"
                              >
                                <Image
                                  field={secondaryLinkIcon}
                                  className="cta-button-image"
                                />
                              </Link>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  </React.Fragment>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="product-media-container">
            <div className="product-media-banner">
              <div className="l-padding">
                {videoId && videoId.value ? (
                  <React.Fragment>
                    <button
                      className="video-modal-cta"
                      type="button"
                      onClick={() => this.setState({ isModalOpen: true })}
                      aria-label={
                        ariaLabel ? ariaLabel.value : 'Watch this video'
                      }
                    >
                      {content}
                    </button>
                    <ModalVideo
                      channel="youtube"
                      isOpen={this.state.isModalOpen}
                      onClose={() => this.setState({ isModalOpen: false })}
                      videoId={videoId.value}
                      youtube={{
                        autoplay: 1,
                        enablejsapi: 1,
                        rel: 0,
                      }}
                    />
                  </React.Fragment>
                ) : (
                  content
                )}
              </div>
            </div>
          </div>

          <div
            className={`product-media-description lr-align-right ${
              isSecondary.value ? 'is-active' : ''
            }`}
          >
            <div className="content-container__grouper">
              <div className="product-media-title-pc">
                <Text field={title} tag="h2" />
              </div>
              <RichText field={description} className="content" />
              {link.value.href ? (
                <div className="cta-grouping">
                  <React.Fragment>
                    {!linkIcon.value.src || !useIconLinkStyle.value ? (
                      <React.Fragment>
                        <Link field={link} className="cta-button-ni-link" />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Link field={link} className="cta-button-link">
                          <Image
                            field={linkIcon}
                            className="cta-button-image"
                          />
                        </Link>
                      </React.Fragment>
                    )}
                    <React.Fragment>
                      {secondaryLink.value.href ? (
                        <React.Fragment>
                          {!secondaryLinkIcon.value.src ||
                          !useIconLinkStyle.value ? (
                            <React.Fragment>
                              <Link
                                field={secondaryLink}
                                className="cta-button-ni-link secondary"
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Link
                                field={secondaryLink}
                                className="cta-button-link secondary"
                              >
                                <Image
                                  field={secondaryLinkIcon}
                                  className="cta-button-image"
                                />
                              </Link>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  </React.Fragment>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    );
    return hasFade && hasFade.value ? (
      <ScrollAnimation
        animateIn="toFadeInUp"
        animateOnce
        delay={100}
        duration={0.5}
      >
        {contentBlock}
      </ScrollAnimation>
    ) : (
      contentBlock
    );
  }
}

LeftRightAlignVideo.propTypes = PropTypes.SitecoreItem.inject({
  id: PropTypes.SingleLineText,
  title: PropTypes.SingleLineText,
  description: PropTypes.RichText,
  image: PropTypes.Image,
  videoId: PropTypes.SingleLineText,
  hasFade: PropTypes.Checkbox,
  useIconLinkStyle: PropTypes.Checkbox,
  link: PropTypes.link,
  linkIcon: PropTypes.Image,
  secondaryLink: PropTypes.link,
  secondaryLinkIcon: PropTypes.Image,
  ariaLabel: PropTypes.SingleLineText,
  backgroundColorOption: PropTypes.Multilist.inject({
    color: PropTypes.SingleLineText,
  }),
  isSecondary: PropTypes.Checkbox,
});

LeftRightAlignVideo.defaultProps = DefaultProps.SitecoreItem.inject({
  id: DefaultProps.SingleLineText,
  title: DefaultProps.SingleLineText,
  description: DefaultProps.RichText,
  image: DefaultProps.Image,
  videoId: DefaultProps.SingleLineText,
  hasFade: DefaultProps.Checkbox,
  useIconLinkStyle: DefaultProps.Checkbox,
  link: DefaultProps.link,
  linkIcon: DefaultProps.Image,
  secondaryLink: DefaultProps.link,
  secondaryLinkIcon: DefaultProps.Image,
  ariaLabel: DefaultProps.SingleLineText,
  isSecondary: DefaultProps.Checkbox,
});

export default LeftRightAlignVideo;
